<template>
  <HWMenu
    placement="bottom-end"
    :menu-items="helpMenuItems"
    list-container-styles="width: 190px;"
    arrow
    list-styles="cursor: pointer; padding: 0px; display: flex"
  >
    <template #trigger>
      <button class="btn-icon">
        <mdicon name="help-circle-outline" size="24"></mdicon>
      </button>
    </template>
    <template #menu-item="{ item }">
      <a
        v-if="item.value.id === 'chat'"
        class="menu-item"
        @click.prevent="openChatWidget"
      >
        <mdicon :name="item.value.icon" size="20" class="mr-2"></mdicon>
        {{ item.text }}
      </a>

      <a
        v-else
        style="color: inherit; font-size: 14px; padding: 10px 15px"
        :href="item.value.link"
        target="_blank"
        class="menu-item"
      >
        <mdicon :name="item.value.icon" size="20" class="mr-2"></mdicon>
        {{ item.text }}
      </a>
    </template>
  </HWMenu>
</template>

<script lang="ts" setup>
import { computed, ref, type ComputedRef } from "vue";

interface HelpMenuItem {
  text: string;
  value: {
    id?: string;
    icon: string;
    link: string;
  };
}

const helpMenuItems: ComputedRef<HelpMenuItem[]> = computed(() => {
  let docUrl = "https://docs.helpwise.io";
  let statusPageUrl = "https://status.helpwise.io";
  if (import.meta.env.VITE_JUSTCALL_ENV) {
    docUrl = "https://help.justcall.io/en/collections/8957424-omnibox";
    statusPageUrl = "https://status.justcall.io";
  }
  const items: HelpMenuItem[] = [
    {
      text: "Service Status",
      value: {
        icon: "heart-pulse",
        link: statusPageUrl,
      },
    },
    {
      text: "Chat with us",
      value: {
        id: "chat",
        icon: "message-outline",
        link: "",
      },
    },
  ];

  items.push({
    text: "Help guides",
    value: {
      icon: "book-open-outline",
      link: docUrl,
    },
  });

  if (!import.meta.env.VITE_JUSTCALL_ENV) {
    items.push({
      text: "Product updates",
      value: {
        icon: "cellphone-arrow-down",
        link: "https://helpwise.io/updates/",
      },
    });
  }
  return items;
});

const openChatWidget = () => {
  if (import.meta.env.VITE_JUSTCALL_ENV) {
    window.Intercom("show");
  } else {
    const currentPageURL = document.URL;

    let regexPattern = /[https:\\/\\/, http:\\/\\/]*inbox.helpwise.io[\\/]*$/gm;

    if (import.meta.env.MODE == "development") {
      regexPattern =
        /[https:\\/\\/, https:\\/\\/]*localinbox.helpwise.io[\\/]*$/m;
    } else if (import.meta.env.MODE == "staging") {
      regexPattern =
        /[https:\\/\\/, http:\\/\\/]staging-vue.helpwise.io[\\/]*$/m;
    }

    if (regexPattern.test(currentPageURL)) {
      window.Helpwise("show");
    } else {
      window.Helpwise("showWithoutIcon");
    }
  }
};
</script>

<style scoped>
.menu-item {
  color: inherit;
  font-size: 14px;
  padding: 10px 15px;
}
</style>
